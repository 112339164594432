/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listConcepts = /* GraphQL */ `
  query ListConcepts {
    listConcepts {
      id
      name
      prerequisites
    }
  }
`;
export const getConcept = /* GraphQL */ `
  query GetConcept($conceptId: String!) {
    getConcept(conceptId: $conceptId) {
      id
      name
      prerequisites
    }
  }
`;
export const listConceptMastery = /* GraphQL */ `
  query ListConceptMastery {
    listConceptMastery {
      conceptId
      level
    }
  }
`;
export const getConceptMastery = /* GraphQL */ `
  query GetConceptMastery($conceptId: String!) {
    getConceptMastery(conceptId: $conceptId) {
      conceptId
      level
    }
  }
`;
export const listPathways = /* GraphQL */ `
  query ListPathways($conceptId: String!) {
    listPathways(conceptId: $conceptId) {
      id
      name
      primary
      type
      level
    }
  }
`;
export const getPathway = /* GraphQL */ `
  query GetPathway($pathwayId: String!) {
    getPathway(pathwayId: $pathwayId) {
      id
      name
      attempted
    }
  }
`;
export const getLatestAttempt = /* GraphQL */ `
  query GetLatestAttempt($conceptId: String!, $level: Int!) {
    getLatestAttempt(conceptId: $conceptId, level: $level)
  }
`;
export const getSubConceptBlocks = /* GraphQL */ `
  query GetSubConceptBlocks(
    $conceptId: String!
    $subConceptId: String!
    $attempt: Int!
  ) {
    getSubConceptBlocks(
      conceptId: $conceptId
      subConceptId: $subConceptId
      attempt: $attempt
    ) {
      id
      type
      data {
        questionType
        text
        html
        link
      }
    }
  }
`;
export const getAlgebrakitSession = /* GraphQL */ `
  query GetAlgebrakitSession(
    $questionType: String!
    $interaction: Interaction!
    $refresh: Boolean
    $repeat: Boolean
  ) {
    getAlgebrakitSession(
      questionType: $questionType
      interaction: $interaction
      refresh: $refresh
      repeat: $repeat
    ) {
      sessionId
      html
    }
  }
`;
export const determineIntervention = /* GraphQL */ `
  query DetermineIntervention(
    $userId: String!
    $interventionCheckType: InterventionCheckType!
    $interaction: Interaction!
  ) {
    determineIntervention(
      userId: $userId
      interventionCheckType: $interventionCheckType
      interaction: $interaction
    ) {
      type
      subConceptId
      blockId
      blocks {
        id
        type
        data {
          questionType
          text
          html
          link
        }
      }
      userId
      message
    }
  }
`;
