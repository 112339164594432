import React from "react";
import { useNavigate, Route, Routes } from "react-router-dom";

import { Extension } from "@mui/icons-material";

import { AppLayout } from "@tiui/remote.pages.app-layout";

import { Concepts } from "./pages/concepts";
import { LearningModes } from "./pages/learningModes";

import { Amplify, Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsconfig);

const App = () => {
  const navigate = useNavigate();

  const Logo: React.FC = () => {
    return (
      <img
        src="https://i.ibb.co/p0gsF5q/cg2.png"
        alt="logo"
        style={{ width: "12%", minWidth: "11em" }}
      />
    );
  };

  return (
    <AppLayout
      sideBarItems={[
        {
          title: "Concepts",
          icon: <Extension />,
          onClick: () => {
            navigate("/");
          },
        },
      ]}
      logo={<Logo />}
      headerAvatarItems={[
        {
          title: "Sign Out",
          link: "#",
          onClick: () => {
            Auth.signOut();
          },
        },
      ]}
    >
      <Routes>
        <Route path="/" element={<Concepts />} />
        <Route path="/concepts/:conceptId" element={<LearningModes />} />
        <Route path="*" element={<h1>Page Not found</h1>} />
      </Routes>
    </AppLayout>
  );
};

export default withAuthenticator(App);
