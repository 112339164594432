import React from "react";

import "./video.css";

export type VideoProps = {
  link: string;
};

export const Video = ({ link }: VideoProps) => {
  return (
    <div className="video-responsive">
      <iframe
        width="100%"
        src={link}
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="Embedded video"
      />
    </div>
  );
};
