import React, { useEffect, useRef, MutableRefObject } from "react";

import {
  Typography,
  Card,
  CardContent,
  Link,
  CardActions,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import { Refresh, AddCircle } from "@mui/icons-material";

import { Amplify, API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import { saveInteraction as saveInteractionMutationString } from "../graphql/mutations";
import { getAlgebrakitSession as getAlgebrakitSessionQueryString } from "../graphql/queries";
import {
  Block,
  ContentType,
  Interaction,
  EventType,
  GetAlgebrakitSessionQuery,
  Session,
  EventDetails,
} from "../API";

import { Algebrakit } from "./algebrakit";
import { Video } from "./video";

import awsExports from "../aws-exports";
import { getIdToken } from "../utils";
Amplify.configure({ ...awsExports, ...{ graphql_headers: getIdToken } });

export type BlockCardProps = {
  block: Block;
  subConceptId: string;
  level: number;
  conceptId: string;
  shouldScrollTo: Boolean;
};

export const BlockCard = ({
  block,
  subConceptId,
  level,
  conceptId,
  shouldScrollTo,
}: BlockCardProps) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const [sessions, setSessions] = React.useState(new Array<Session>());

  const recordInteraction = async (interaction: Interaction) => {
    try {
      await API.graphql(
        graphqlOperation(saveInteractionMutationString, {
          interaction,
        })
      );
    } catch (e) {
      console.error("Exception while recording interaction:", e);
    }
  };

  useEffect(() => {
    if (block.type !== ContentType.QUESTION) {
      const interaction = {
        attempt: 1,
        eventType: EventType.READ,
        blockId: block.id,
        subConceptId,
        conceptId,
        level,
      } as Interaction;

      // recordInteraction(interaction); // Temporarily disabled
    }

    return () => {};
  }, []);

  useEffect(() => {
    shouldScrollTo && ref.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const onInteraction = async (
    eventType: EventType,
    eventDetails: EventDetails,
    sessionId?: String
  ) => {
    await recordInteraction({
      attempt: 1,
      eventType,
      eventDetails,
      blockId: block.id,
      sessionId,
      subConceptId,
      conceptId,
      level,
    } as Interaction);
  };

  const getSessions = async (
    questionType: string,
    interaction: Interaction,
    refresh: Boolean,
    repeat: Boolean
  ) => {
    try {
      const response = (await API.graphql(
        graphqlOperation(getAlgebrakitSessionQueryString, {
          questionType,
          interaction,
          refresh,
          repeat,
        })
      )) as GraphQLResult;
      const responseData = response.data as GetAlgebrakitSessionQuery;
      setSessions(responseData.getAlgebrakitSession);
    } catch (e) {
      console.error("Exception while fetching session:", e);
    }
  };

  useEffect(() => {
    if (block.type === ContentType.QUESTION && block.data.questionType) {
      getSessions(
        block.data.questionType,
        {
          attempt: 1,
          eventType: EventType.GET_SESSION,
          blockId: block.id,
          subConceptId,
          conceptId,
          level,
        } as Interaction,
        false,
        false
      );
    }
  }, []);

  const onRefresh = () => {
    getSessions(
      block.data.questionType || "",
      {
        attempt: 1,
        eventType: EventType.GET_SESSION,
        blockId: block.id,
        subConceptId,
        conceptId,
        level,
      } as Interaction,
      true,
      false
    );
  };

  const onRepeat = () => {
    getSessions(
      block.data.questionType || "",
      {
        attempt: 1,
        eventType: EventType.GET_SESSION,
        blockId: block.id,
        subConceptId,
        conceptId,
        level,
      } as Interaction,
      false,
      true
    );
  };

  return (
    <Card sx={{ width: "100%" }} variant="outlined">
      <CardContent>
        {<div ref={ref}></div>}
        {block.type === ContentType.TEXT && (
          <Typography>{block.data.text}</Typography>
        )}
        {block.type === ContentType.QUESTION &&
          block.data.questionType &&
          sessions.map((session, index) => (
            <Algebrakit
              session={session}
              onInteraction={onInteraction}
              key={`${block.id}:${index}`}
            />
          ))}
        {block.type === ContentType.HTML && block.data.html && (
          <div dangerouslySetInnerHTML={{ __html: block.data.html }} />
        )}
        {block.type === ContentType.VIDEO && block.data.link && (
          <Video link={block.data.link} />
        )}
        {block.type === ContentType.LINK && block.data.link && (
          <Link target="_blank" href={block.data.link} rel="noreferrer">
            {block.data.link}
          </Link>
        )}
      </CardContent>
      {block.type === ContentType.QUESTION && block.data.questionType && (
        <CardActions>
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{ display: "flex" }}
          >
            <Tooltip title="Refresh questions">
              <IconButton aria-label="refresh" onClick={onRefresh}>
                <Refresh />
              </IconButton>
            </Tooltip>
            <Tooltip title="Repeat question of same type">
              <IconButton aria-label="repeat" onClick={onRepeat}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};
