import React, { useMemo } from "react";

import { Box, Grid, CircularProgress } from "@mui/material";

import { Amplify, API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import { ConceptCard } from "../components/conceptCard";

import {
  Concept,
  ListConceptsQuery,
  ListConceptMasteryQuery,
  ConceptMastery,
} from "../API";
import {
  listConcepts as listConceptsQueryString,
  listConceptMastery as listConceptMasteryQueryString,
} from "../graphql/queries";

import awsExports from "../aws-exports";
import { getIdToken } from "../utils";
Amplify.configure({ ...awsExports, ...{ graphql_headers: getIdToken } });

export type ConceptMasteryMap = {
  [key: string]: {
    level: number;
  };
};

export type ConceptsProps = {};

export const Concepts = ({}: ConceptsProps) => {
  const [loading, setLoading] = React.useState(true);
  const [concepts, setConcepts] = React.useState<Concept[]>([]);
  const [conceptMasteryMap, setConceptMasteryMap] =
    React.useState<ConceptMasteryMap>({});
  const [error, setError] = React.useState("");

  const getData = async () => {
    setError("");
    setLoading(true);

    try {
      const response = (await API.graphql(
        graphqlOperation(listConceptMasteryQueryString)
      )) as GraphQLResult;
      const responseData = response.data as ListConceptMasteryQuery;
      if (responseData.listConceptMastery) {
        const map = responseData.listConceptMastery.reduce(
          (map: ConceptMasteryMap, obj: ConceptMastery) => {
            map[obj.conceptId] = {
              level: obj.level,
            };
            return map;
          },
          {}
        );
        map["970b5549933461b3bd8b86141090a0b3"] = { level: 4 }; // Temporary
        map["a0bc49053bfb76af7d2bf60efb1cc7fc"] = { level: 3 };
        setConceptMasteryMap(map);
      }
    } catch (e) {
      setConceptMasteryMap({} as ConceptMasteryMap);
      setError("Something went wrong while fetching concept mastery data. ");
    }

    try {
      const response = (await API.graphql(
        graphqlOperation(listConceptsQueryString)
      )) as GraphQLResult;
      const responseData = response.data as ListConceptsQuery;
      if (responseData.listConcepts) {
        let conceptsData = responseData.listConcepts;
        conceptsData = conceptsData.map((concept) => { //  Temporary
          if (
            [
              "970b5549933461b3bd8b86141090a0b3",
              "a0bc49053bfb76af7d2bf60efb1cc7fc",
              "6630cb1043f4dd8fe0f73e51fe02e83a",
            ].includes(concept.id)
          ) {
            concept.prerequisites = ["xyz"];
          }
          return concept;
        });
        setConcepts(responseData.listConcepts);
      }
    } catch (e) {
      setConcepts(new Array<Concept>());
      setError(`${error}Something went wrong while fetching concepts`);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  const generateConceptCards = () => {
    return concepts.map((concept: Concept) => (
      <Grid item xs={1} key={concept.id}>
        <ConceptCard
          conceptName={concept.name}
          conceptId={concept.id}
          level={conceptMasteryMap[concept.id]?.level || 0}
          enabled={
            !concept.prerequisites ||
            concept.prerequisites.every(
              (prereq) => conceptMasteryMap[prereq]?.level === 4
            )
          }
        />
      </Grid>
    ));
  };

  const listConceptCards = useMemo(
    () => generateConceptCards(),
    [concepts, conceptMasteryMap]
  );

  return (
    <Box justifyContent="center" alignItems="center" sx={{ display: "flex" }}>
      {loading && <CircularProgress />}
      {!loading && error === "" && (
        <Grid container spacing={4} columns={4}>
          {listConceptCards}
        </Grid>
      )}
      {!loading && error !== "" && <div>{error}</div>}
    </Box>
  );
};
