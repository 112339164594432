/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const interventionHappened = /* GraphQL */ `
  subscription InterventionHappened($userId: String, $subConceptId: String) {
    interventionHappened(userId: $userId, subConceptId: $subConceptId) {
      type
      subConceptId
      blockId
      blocks {
        id
        type
        data {
          questionType
          text
          html
          link
        }
      }
      userId
      message
    }
  }
`;
