import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Rating,
  Tooltip,
} from "@mui/material";

export type ConceptCardProps = {
  conceptName: String;
  conceptId: String;
  level: number;
  enabled: Boolean;
};

export const ConceptCard = ({
  conceptName,
  conceptId,
  level,
  enabled,
}: ConceptCardProps) => {
  const navigate = useNavigate();

  const handleLearnButtonClick = () => {
    if (enabled) {
      navigate(`/concepts/${conceptId}`);
    }
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {conceptName}
        </Typography>
        <Rating name="level" value={level} max={4} readOnly />
      </CardContent>
      <CardActions>
        <Tooltip
          title={enabled ? "" : "Please complete all previous concepts."}
        >
          <Button
            size="small"
            onClick={handleLearnButtonClick}
            variant={enabled ? "contained" : "outlined"}
          >
            Learn
          </Button>
        </Tooltip>
      </CardActions>
    </Card>
  );
};
