import React, { useEffect } from "react";

import { EventType, Session, EventDetails } from "../API";

export type AlgebrakitProps = {
  session: Session;
  onInteraction: (
    eventType: EventType,
    eventDetails: EventDetails,
    sessionId?: String
  ) => void;
};

interface IAlgebraKIT {
  addExerciseListener: (...args: any[]) => void;
  removeExerciseListener: (sessionId: string, func: Function) => void;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "akit-exercise": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
  interface Window {
    AlgebraKIT: IAlgebraKIT;
  }
}

export const Algebrakit = ({ session, onInteraction }: AlgebrakitProps) => {
  const registerEvent = (eventData: any) => {
    console.log("Algebrakit Event", eventData);
    let details = {
      tags: [],
      headLines: [],
      subLines: [],
    } as EventDetails;
    if (eventData?.data?.items) {
      for (let item of eventData.data.items) {
        if (item.feedback !== null) {
          if (item.feedback?.tags) {
            for (let tag of item.feedback.tags) {
              details.tags.push(tag.ID);
            }
          }

          if (item.feedback?.description?.headLine) {
            let message = "";
            for (let part of item.feedback.description.headLine) {
              message += part.content;
            }
            details.headLines.push(message);
          }

          if (item.feedback?.description?.subLine) {
            let message = "";
            for (let part of item.feedback.description.subLine) {
              message += part.content;
            }
            details.subLines.push(message);
          }
        }
      }
    }
    switch (eventData.event) {
      case "interaction-submit-state-changed":
        onInteraction(EventType.NEW_ATTEMPT, details, session.sessionId);
        break;
      case "interaction-evaluate":
        const exerciseFinished = eventData.data.exerciseFinished;
        if (exerciseFinished) {
          onInteraction(EventType.ATTEMPT_SUCCESS, details, session.sessionId);
        } else {
          onInteraction(EventType.ATTEMPT_FAILURE, details, session.sessionId);
        }
        break;
      case "interaction-hint":
        onInteraction(EventType.ACCESS_HINT, details, session.sessionId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (session?.sessionId && session.sessionId !== "") {
      console.log("Registering algebrakit event listener");
      window.AlgebraKIT.addExerciseListener(session.sessionId, registerEvent);

      return () => {
        window.AlgebraKIT.removeExerciseListener(
          session.sessionId,
          registerEvent
        );
      };
    }
  }, [session]);

  return (
    <div>
      {session?.sessionId && session.sessionId !== "" && session?.html && (
        <div dangerouslySetInnerHTML={{ __html: session.html }} />
      )}
      {!session?.sessionId ||
        (session.sessionId === "" && <div>Loading Exercise</div>)}
    </div>
  );
};
