/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Interaction = {
  attempt: number,
  eventType: EventType,
  eventMessage?: string | null,
  eventDetails?: EventDetails | null,
  blockId: string,
  sessionId?: string | null,
  subConceptId: string,
  conceptId: string,
  level: number,
  timestamp?: number | null,
};

export enum EventType {
  READ = "READ",
  ATTEMPT_SUCCESS = "ATTEMPT_SUCCESS",
  ATTEMPT_FAILURE = "ATTEMPT_FAILURE",
  ACCESS_HINT = "ACCESS_HINT",
  NEW_ATTEMPT = "NEW_ATTEMPT",
  GET_SESSION = "GET_SESSION",
}


export type EventDetails = {
  tags: Array< string >,
  headLines: Array< string >,
  subLines: Array< string >,
};

export type Intervention = {
  type: InterventionType,
  blocks?: Array< BlockInput > | null,
  message?: string | null,
};

export enum InterventionType {
  NEW_BLOCKS = "NEW_BLOCKS",
  PROMPT_HELP = "PROMPT_HELP",
}


export type BlockInput = {
  id: string,
  type: ContentType,
  data: ContentDataInput,
};

export enum ContentType {
  VIDEO = "VIDEO",
  TEXT = "TEXT",
  LINK = "LINK",
  QUESTION = "QUESTION",
  PROMPT = "PROMPT",
  HTML = "HTML",
}


export type ContentDataInput = {
  questionType?: string | null,
  text?: string | null,
  html?: string | null,
  link?: string | null,
};

export type InterventionResult = {
  __typename: "InterventionResult",
  type: InterventionType,
  subConceptId?: string | null,
  blockId?: string | null,
  blocks?:  Array<Block > | null,
  userId?: string | null,
  message?: string | null,
};

export type Block = {
  __typename: "Block",
  id: string,
  type: ContentType,
  data: ContentData,
};

export type ContentData = {
  __typename: "ContentData",
  questionType?: string | null,
  text?: string | null,
  html?: string | null,
  link?: string | null,
};

export type Concept = {
  __typename: "Concept",
  id: string,
  name: string,
  prerequisites?: Array< string > | null,
};

export type ConceptMastery = {
  __typename: "ConceptMastery",
  conceptId: string,
  level: number,
};

export type Pathway = {
  __typename: "Pathway",
  id: string,
  name: string,
  primary: boolean,
  type: PathwayType,
  level: number,
};

export enum PathwayType {
  RESOURCE = "RESOURCE",
  EXERCISE = "EXERCISE",
}


export type SubConcept = {
  __typename: "SubConcept",
  id: string,
  name: string,
  attempted: boolean,
};

export type Session = {
  __typename: "Session",
  sessionId: string,
  html: string,
};

export enum InterventionCheckType {
  IDLE_TIME = "IDLE_TIME",
  MISTAKES = "MISTAKES",
}


export type SaveInteractionMutationVariables = {
  interaction?: Interaction | null,
};

export type SaveInteractionMutation = {
  saveInteraction: boolean,
};

export type InterveneMutationVariables = {
  userId: string,
  interaction: Interaction,
  intervention: Intervention,
};

export type InterveneMutation = {
  intervene:  {
    __typename: "InterventionResult",
    type: InterventionType,
    subConceptId?: string | null,
    blockId?: string | null,
    blocks?:  Array< {
      __typename: "Block",
      id: string,
      type: ContentType,
      data:  {
        __typename: "ContentData",
        questionType?: string | null,
        text?: string | null,
        html?: string | null,
        link?: string | null,
      },
    } > | null,
    userId?: string | null,
    message?: string | null,
  },
};

export type SubmitExerciseMutationVariables = {
  conceptId?: string | null,
  level: number,
  attempt: number,
};

export type SubmitExerciseMutation = {
  submitExercise: boolean,
};

export type ListConceptsQuery = {
  listConcepts:  Array< {
    __typename: "Concept",
    id: string,
    name: string,
    prerequisites?: Array< string > | null,
  } >,
};

export type GetConceptQueryVariables = {
  conceptId: string,
};

export type GetConceptQuery = {
  getConcept?:  {
    __typename: "Concept",
    id: string,
    name: string,
    prerequisites?: Array< string > | null,
  } | null,
};

export type ListConceptMasteryQuery = {
  listConceptMastery:  Array< {
    __typename: "ConceptMastery",
    conceptId: string,
    level: number,
  } >,
};

export type GetConceptMasteryQueryVariables = {
  conceptId: string,
};

export type GetConceptMasteryQuery = {
  getConceptMastery?:  {
    __typename: "ConceptMastery",
    conceptId: string,
    level: number,
  } | null,
};

export type ListPathwaysQueryVariables = {
  conceptId: string,
};

export type ListPathwaysQuery = {
  listPathways:  Array< {
    __typename: "Pathway",
    id: string,
    name: string,
    primary: boolean,
    type: PathwayType,
    level: number,
  } >,
};

export type GetPathwayQueryVariables = {
  pathwayId: string,
};

export type GetPathwayQuery = {
  getPathway:  Array< {
    __typename: "SubConcept",
    id: string,
    name: string,
    attempted: boolean,
  } >,
};

export type GetLatestAttemptQueryVariables = {
  conceptId: string,
  level: number,
};

export type GetLatestAttemptQuery = {
  getLatestAttempt?: number | null,
};

export type GetSubConceptBlocksQueryVariables = {
  conceptId: string,
  subConceptId: string,
  attempt: number,
};

export type GetSubConceptBlocksQuery = {
  getSubConceptBlocks:  Array< {
    __typename: "Block",
    id: string,
    type: ContentType,
    data:  {
      __typename: "ContentData",
      questionType?: string | null,
      text?: string | null,
      html?: string | null,
      link?: string | null,
    },
  } >,
};

export type GetAlgebrakitSessionQueryVariables = {
  questionType: string,
  interaction: Interaction,
  refresh?: boolean | null,
  repeat?: boolean | null,
};

export type GetAlgebrakitSessionQuery = {
  getAlgebrakitSession:  Array< {
    __typename: "Session",
    sessionId: string,
    html: string,
  } >,
};

export type DetermineInterventionQueryVariables = {
  userId: string,
  interventionCheckType: InterventionCheckType,
  interaction: Interaction,
};

export type DetermineInterventionQuery = {
  determineIntervention?:  {
    __typename: "InterventionResult",
    type: InterventionType,
    subConceptId?: string | null,
    blockId?: string | null,
    blocks?:  Array< {
      __typename: "Block",
      id: string,
      type: ContentType,
      data:  {
        __typename: "ContentData",
        questionType?: string | null,
        text?: string | null,
        html?: string | null,
        link?: string | null,
      },
    } > | null,
    userId?: string | null,
    message?: string | null,
  } | null,
};

export type InterventionHappenedSubscriptionVariables = {
  userId?: string | null,
  subConceptId?: string | null,
};

export type InterventionHappenedSubscription = {
  interventionHappened?:  {
    __typename: "InterventionResult",
    type: InterventionType,
    subConceptId?: string | null,
    blockId?: string | null,
    blocks?:  Array< {
      __typename: "Block",
      id: string,
      type: ContentType,
      data:  {
        __typename: "ContentData",
        questionType?: string | null,
        text?: string | null,
        html?: string | null,
        link?: string | null,
      },
    } > | null,
    userId?: string | null,
    message?: string | null,
  } | null,
};
