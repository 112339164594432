import React, { useEffect } from "react";

import {
  Box,
  CircularProgress,
  Button,
  Rating,
  Stack,
  Typography,
} from "@mui/material";

import { Amplify, API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import { getPathway as getPathwayQueryString } from "../graphql/queries";
import { submitExercise as submitExerciseMutationString } from "../graphql/mutations";
import { SubConcept, GetPathwayQuery, Pathway, PathwayType } from "../API";

import { SubConceptAccordion } from "./subConceptAccordion";

import awsExports from "../aws-exports";
import { getIdToken } from "../utils";
Amplify.configure({ ...awsExports, ...{ graphql_headers: getIdToken } });

export type LearnProps = {
  currentLevel: number;
  conceptId: string;
  pathways: Pathway[];
  isExercise: Boolean;
  onLevelChange: (level: number) => void;
};

export const Learn = ({
  currentLevel,
  conceptId,
  pathways,
  isExercise,
  onLevelChange,
}: LearnProps) => {
  const [subConcepts, setSubConcepts] = React.useState(new Array<SubConcept>());
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [showPathway, setShowPathway] = React.useState(false);
  const [error, setError] = React.useState("");
  const [currentPathway, setCurrentPathway] = React.useState("");
  const [newLevel, setNewLevel] = React.useState(Math.min(currentLevel + 1, 4));

  const getPathway = async (pathwayId: string) => {
    setLoading(true);
    setError("");
    try {
      const response = (await API.graphql(
        graphqlOperation(getPathwayQueryString, {
          pathwayId,
        })
      )) as GraphQLResult;
      const responseData = response.data as GetPathwayQuery;

      if (responseData.getPathway) {
        setSubConcepts(responseData.getPathway);
      }
      setShowPathway(true);
    } catch (e) {
      setError("Something went wrong while fetching resources.");
      console.error("Exception while fetching session:", e);
    }
    setLoading(false);
  };

  const start = () => {
    getPathway(currentPathway);
  };

  const submit = async () => {
    setSubmitting(true);

    try {
      const response = (await API.graphql(
        graphqlOperation(submitExerciseMutationString, {
          conceptId,
          level: newLevel,
          attempt: 1,
        })
      )) as GraphQLResult;
      onLevelChange(newLevel);
      setShowPathway(false);
    } catch (e) {
      console.error("Exception while submitting exercise:", e);
    }

    setSubmitting(false);
  };

  useEffect(() => {
    let desiredLevel = Math.min(currentLevel + 1, 4);
    setNewLevel(desiredLevel);
    setError("");
    let pathwayId = "";
    if (isExercise) {
      if (desiredLevel === 1) {
        setError("Please complete Learn level before starting exercises.");
        return () => {};
      }
      pathways.forEach((pathway) => {
        if (
          pathway.level === desiredLevel &&
          pathway.type === PathwayType.EXERCISE
        ) {
          pathwayId = pathway.id;
        }
      });
    } else {
      pathways.forEach((pathway) => {
        if (pathway.primary && pathway.type === PathwayType.RESOURCE) {
          pathwayId = pathway.id;
        }
      });
    }

    if (pathwayId === "") {
      setError("Unable to find resources for this level");
    } else {
      setCurrentPathway(pathwayId);
      if (!isExercise && desiredLevel > 1) {
        getPathway(pathwayId);
      }
    }
  }, [pathways, currentLevel]);

  return (
    <Box justifyContent="center" alignItems="center" sx={{ display: "flex" }}>
      {loading && <CircularProgress />}
      {!loading && !showPathway && error === "" && (
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography component="legend">
            You have earned {currentLevel} stars for this concept
          </Typography>

          <Rating name="level" value={currentLevel} max={4} readOnly />
          <Button
            onClick={start}
            variant="contained"
            disabled={isExercise && currentLevel < 1}
          >
            {isExercise ? "Start Exercise" : "Start Learning"}
          </Button>
        </Stack>
      )}

      {!loading && showPathway && error === "" && (
        <Stack
          sx={{ width: "95%" }}
          direction="column"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          {subConcepts.map((subConcept) => (
            <SubConceptAccordion
              subConcept={subConcept}
              level={isExercise ? newLevel : 1}
              conceptId={conceptId}
              key={subConcept.id}
            />
          ))}
          <Button
            onClick={submit}
            variant="contained"
            disabled={submitting || (!isExercise && currentLevel > 0)}
          >
            Submit
          </Button>
        </Stack>
      )}
      {!loading && error !== "" && <div>{error}</div>}
    </Box>
  );
};
