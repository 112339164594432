import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, CircularProgress } from "@mui/material";
import { Extension, Category } from "@mui/icons-material";

import { Amplify, API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import { HeaderWithTabs } from "@tiui/remote.ui.header-with-tabs";

import { Learn } from "../components/learn";
import { ConceptMasteryMap } from "./concepts";

import {
  ListConceptMasteryQuery,
  ConceptMastery,
  GetConceptQuery,
  ListPathwaysQuery,
  Pathway,
} from "../API";
import {
  listConceptMastery as listConceptMasteryQueryString,
  getConcept as getConceptQueryString,
  listPathways as listPathwaysQueryString,
} from "../graphql/queries";

import awsExports from "../aws-exports";
import { getIdToken } from "../utils";
import path from "path";
Amplify.configure({ ...awsExports, ...{ graphql_headers: getIdToken } });

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

export type LearningModesProps = {};

export const LearningModes = ({}: LearningModesProps) => {
  const [loading, setLoading] = React.useState(true);
  const [accessible, setAccesible] = React.useState(false);
  const [name, setName] = React.useState("");
  const [prereqs, setPrereqs] = React.useState(new Array<string>());
  const [level, setLevel] = React.useState(0);
  const [error, setError] = React.useState("");
  const [pathways, setPathways] = React.useState(new Array<Pathway>());
  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState(0);
  const { conceptId } = useParams();

  const currentDirectory = [
    { title: "Concepts", icon: <Extension /> },
    {
      title: name,
      icon: <Category />,
      activePage: true,
    },
  ];
  const tabItems = ["Learn", "Exercise"];

  const onTabChange = (index: number) => {
    setTabValue(index);
  };

  const onCrumbClick = (clickedCrumbIndex: number) => {
    if (clickedCrumbIndex === 0) {
      navigate("/");
    }
  };

  const checkAccessibility = async () => {
    setLoading(true);
    setError("");
    try {
      const response = (await API.graphql(
        graphqlOperation(listConceptMasteryQueryString)
      )) as GraphQLResult;
      const responseData = response.data as ListConceptMasteryQuery;
      let map = {} as ConceptMasteryMap;
      if (responseData.listConceptMastery) {
        map = responseData.listConceptMastery.reduce(
          (map: ConceptMasteryMap, obj: ConceptMastery) => {
            map[obj.conceptId] = {
              level: obj.level,
            };
            return map;
          },
          {}
        );
      }
      if (conceptId) {
        setLevel(map[conceptId]?.level || 0);
      }

      let inaccessible = false;
      for (let prereq of prereqs) {
        if (prereq in map) {
          if (map[prereq].level < 4) {
            inaccessible = true;
            break;
          }
        } else {
          inaccessible = true;
          break;
        }
      }
      setAccesible(!inaccessible);

      if (inaccessible) {
        setError("Please complete prerequisites before starting this concept.");
        setLoading(false);
      }
    } catch (e) {
      console.log("Something went wrong while fetching concept mastery data.");
      setLoading(false);
    }
  };

  const getConceptDetails = async () => {
    setLoading(true);
    setError("");
    try {
      const response = (await API.graphql(
        graphqlOperation(getConceptQueryString, {
          conceptId,
        })
      )) as GraphQLResult;
      const responseData = response.data as GetConceptQuery;
      if (responseData.getConcept) {
        setName(responseData.getConcept.name);
        setPrereqs(responseData.getConcept.prerequisites || []);
      } else {
        setError("Could not find the concept you were looking for.");
      }
    } catch (e) {
      console.log(e);
      setError("Something went wrong while fetching concepts");
    }
    setLoading(false);
  };

  const listPathwayData = async () => {
    if (accessible) {
      setLoading(true);
      setError("");
      try {
        const response = (await API.graphql(
          graphqlOperation(listPathwaysQueryString, {
            conceptId,
          })
        )) as GraphQLResult;
        const responseData = response.data as ListPathwaysQuery;
        if (responseData.listPathways) {
          setPathways(responseData.listPathways);
        } else {
          setError("No Pathways found for this concept");
        }
      } catch (e) {
        console.log(e);
        setError("Something went wrong while fetching resource pathways");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getConceptDetails();
  }, []);

  useEffect(() => {
    checkAccessibility();
  }, [name]);

  useEffect(() => {
    listPathwayData();
  }, [accessible]);

  return (
    <Box>
      <HeaderWithTabs
        currentTab={tabValue}
        currentDirectory={currentDirectory}
        tabItems={tabItems}
        onTabChange={(event, newTabIndex) => onTabChange(newTabIndex)}
        onCrumbClick={onCrumbClick}
      />
      <div style={{ height: "24px", width: "100%" }} /> {/* spacer */}
      {loading && (
        <Box
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex" }}
        >
          <CircularProgress />{" "}
        </Box>
      )}
      {!loading && error !== "" && <div>{error}</div>}
      {!loading && error === "" && (
        <div>
          <TabPanel value={tabValue} index={0}>
            <Learn
              currentLevel={level}
              conceptId={conceptId || ""}
              pathways={pathways}
              isExercise={false}
              onLevelChange={(newLevel: number) => {
                setLevel(newLevel);
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Learn
              currentLevel={level}
              conceptId={conceptId || ""}
              pathways={pathways}
              isExercise={true}
              onLevelChange={(newLevel: number) => {
                setLevel(newLevel);
              }}
            />
          </TabPanel>
        </div>
      )}
    </Box>
  );
};
