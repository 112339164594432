import React, { useEffect } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Box,
  Stack,
  Snackbar,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";
import { GraphQLSubscription } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import { getSubConceptBlocks as getSubConceptBlocksQueryString } from "../graphql/queries";
import { interventionHappened } from "../graphql/subscriptions";
import {
  SubConcept,
  Block,
  GetSubConceptBlocksQuery,
  InterventionHappenedSubscription,
  InterventionResult,
} from "../API";
import { BlockCard } from "./blockCard";

import awsExports from "../aws-exports";
import { getIdToken } from "../utils";
Amplify.configure({ ...awsExports, ...{ graphql_headers: getIdToken } });

export type SubConceptAccordionProps = {
  subConcept: SubConcept;
  level: number;
  conceptId: string;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SubConceptAccordion = ({
  subConcept,
  conceptId,
  level,
}: SubConceptAccordionProps) => {
  const [loading, setLoading] = React.useState(false);
  const [blocks, setBlocks] = React.useState(new Array<Block>());
  const [intervention, setIntervention] = React.useState(
    {} as InterventionResult
  );
  const [fetched, setFetched] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");

  const handleChange = async (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded);
    if (!fetched && isExpanded) {
      setLoading(true);
      try {
        const response = (await API.graphql(
          graphqlOperation(getSubConceptBlocksQueryString, {
            conceptId,
            subConceptId: subConcept.id,
            attempt: 1,
          })
        )) as GraphQLResult;
        const responseData = response.data as GetSubConceptBlocksQuery;

        if (responseData.getSubConceptBlocks) {
          setBlocks(responseData.getSubConceptBlocks);
        }
        setFetched(true);
      } catch (e) {
        console.error("Exception while fetching subconcept blocks:", e);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    Auth.currentUserInfo().then((data) => {
      setEmail(data.attributes.email);
    });
  }, []);

  useEffect(() => {
    if (email !== "") {
      const subscription = API.graphql<
        GraphQLSubscription<typeof interventionHappened>
      >(
        graphqlOperation(interventionHappened, {
          userId: email,
          subConceptId: subConcept.id,
        })
      ).subscribe({
        next: ({ provider, value }) => {
          const adaptiveData = value.data as InterventionHappenedSubscription;
          console.log({ provider, value });
          if (adaptiveData.interventionHappened !== null) {
            setIntervention(
              adaptiveData.interventionHappened as InterventionResult
            );
            setToastMessage(
              adaptiveData.interventionHappened?.message || "Adaptive content"
            );
          }
        },
        error: (error) =>
          console.error("Exception while subscribing to intervention:", error),
      });

      return () => {
        subscription.unsubscribe();
      };
    }

    return () => {};
  }, [email]);

  useEffect(() => {
    if (
      intervention?.blocks !== null &&
      intervention.blocks !== undefined &&
      intervention.blocks.length > 0
    ) {
      let blockIndex = blocks.findIndex(
        (block) => block.id === intervention.blockId
      );
      let newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 0, ...intervention.blocks);
      setBlocks(newBlocks);
    }
  }, [intervention]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setToastMessage("");
  };

  return (
    <Accordion
      sx={{ width: "100%" }}
      onChange={handleChange}
      key={subConcept.id}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`panel${subConcept.id}-content`}
        id={`panel${subConcept.id}-header`}
      >
        <Typography variant="h6">{subConcept.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex" }}
        >
          <Snackbar
            autoHideDuration={5000}
            open={toastMessage != ""}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {toastMessage}
            </Alert>
          </Snackbar>
          {loading && <CircularProgress />}
          {!loading && fetched && (
            <Stack
              direction="column"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {blocks.map((block) => (
                <BlockCard
                  block={block}
                  subConceptId={subConcept.id}
                  conceptId={conceptId}
                  level={level}
                  key={block.id}
                  shouldScrollTo={
                    intervention?.blocks !== null &&
                    intervention.blocks !== undefined &&
                    intervention.blocks.length > 0 &&
                    intervention.blocks[0].id === block.id
                  }
                />
              ))}
            </Stack>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
