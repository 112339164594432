/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const saveInteraction = /* GraphQL */ `
  mutation SaveInteraction($interaction: Interaction) {
    saveInteraction(interaction: $interaction)
  }
`;
export const intervene = /* GraphQL */ `
  mutation Intervene(
    $userId: String!
    $interaction: Interaction!
    $intervention: Intervention!
  ) {
    intervene(
      userId: $userId
      interaction: $interaction
      intervention: $intervention
    ) {
      type
      subConceptId
      blockId
      blocks {
        id
        type
        data {
          questionType
          text
          html
          link
        }
      }
      userId
      message
    }
  }
`;
export const submitExercise = /* GraphQL */ `
  mutation SubmitExercise($conceptId: String, $level: Int!, $attempt: Int!) {
    submitExercise(conceptId: $conceptId, level: $level, attempt: $attempt)
  }
`;
